.datetime-picker.focus .picker,
.calendar.focus>.datetime-picker .picker {
	display   : grid;
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
	border    : 1px solid #E5E0EB;
}

.datetime-picker {
	position             : relative;
	font-size            : 14px;
	-webkit-touch-callout: none;
	-webkit-user-select  : none;
	-moz-user-select     : none;
	-ms-user-select      : none;
	user-select          : none;
}

.datetime-picker .picker {
	height               : 300px;
	background-color     : white;
	display              : none;
	grid-template-columns: auto auto;
	grid-template-areas  : 'date time';
	position             : absolute;
	border               : 1px solid black;
	z-index              : 100;
}

.datetime-picker .picker>.date-picker {
	width              : 300px;
	height             : 300px;
	top                : 0;
	left               : 0;
	z-index            : 1;
	grid-area          : date;
	display            : grid;
	grid-template-rows : 2.5rem 1.2rem auto 2.5rem 0.5rem;
	grid-template-areas: 'year-month-picker''day-of-week-header''day-picker''today-button''.';
	align-items        : stretch;
	justify-items      : stretch;
}

.datetime-picker .picker>.date-picker>.year-month-picker {
	/* height: 40px; */
	display              : grid;
	grid-template-columns: auto auto 70px 70px auto auto;
	grid-template-areas  : 'left-arrow . month year . right-arrow';
	justify-items        : center;
	align-items          : center;
	grid-area            : year-month-picker;
	background-color     : var(--accent-color);
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker {
	grid-area       : month;
	cursor          : pointer;
	text-transform  : uppercase;
	position        : relative;
	width           : 100%;
	height          : 100%;
	background-color: white;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker>.month-picker-label {
	position        : absolute;
	top             : 0;
	left            : 0;
	width           : 100%;
	height          : 100%;
	z-index         : 1;
	text-align      : center;
	color           : white;
	display         : flex;
	justify-content : center;
	align-items     : center;
	background-color: var(--accent-color);
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker>.month-picker-options {
	position        : absolute;
	top             : 0;
	left            : 0;
	width           : 100%;
	z-index         : 2;
	text-align      : center;
	background-color: white;
	max-height      : 300px;
	overflow-y      : scroll;
	border          : 1px solid var(--accent-color);
	color           : black;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker>.month-picker-options>.month-options {
	text-align : center;
	height     : 2rem;
	line-height: 2rem;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker>.month-picker-options>.month-options.active {
	background-color: var(--accent-color);
	color           : white;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.month-picker>.month-picker-options.options-hidden {
	visibility: hidden;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker {
	grid-area       : year;
	cursor          : pointer;
	position        : relative;
	width           : 100%;
	height          : 100%;
	background-color: var(--accent-color);
	color           : #fff;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker>.year-picker-label {
	position        : absolute;
	top             : 0;
	left            : 0;
	width           : 100%;
	height          : 100%;
	z-index         : 1;
	text-align      : center;
	background-color: var(--accent-color);
	color           : #fff;
	display         : flex;
	justify-content : center;
	align-items     : center;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker>.year-picker-options {
	position        : absolute;
	top             : 0;
	left            : 0;
	width           : 100%;
	z-index         : 2;
	text-align      : center;
	background-color: white;
	max-height      : 300px;
	overflow-y      : scroll;
	border          : 1px solid var(--accent-color);
	color           : black;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker>.year-picker-options>.year-options {
	text-align : center;
	height     : 2rem;
	line-height: 2rem;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker>.year-picker-options>.year-options.active {
	background-color: var(--accent-color);
	color           : white;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.year-picker>.year-picker-options.options-hidden {
	visibility: hidden;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.prev-month.button {
	grid-area          : left-arrow !important;
	cursor             : pointer;
	background-color   : transparent;
	justify-self       : start;
	align-self         : center;
	margin             : 0 !important;
	padding            : 0 !important;
	height             : 100%;
	width              : 80px !important;
	color              : #fff;
	display            : grid;
	place-content      : center;
	background-image   : url('/images/arrow.svg');
	transform          : rotate(180deg);
	background-repeat  : no-repeat;
	background-position: center;
	min-width          : 80px !important;
	border             : none;
}

.datetime-picker .picker>.date-picker>.year-month-picker>.next-month.button {
	grid-area          : right-arrow !important;
	cursor             : pointer;
	background-color   : transparent;
	justify-self       : end;
	align-self         : center;
	margin             : 0 !important;
	padding            : 0 !important;
	height             : 100%;
	width              : 80px !important;
	min-width          : 80px !important;
	color              : #fff;
	display            : grid;
	place-content      : center;
	background-image   : url('/images/arrow.svg');
	background-repeat  : no-repeat;
	background-position: center;
	border             : none;
}

.datetime-picker .picker>.date-picker>.day-of-week-header {
	display              : grid;
	justify-items        : center;
	align-items          : center;
	grid-template-columns: repeat(7, 1fr);
	grid-area            : day-of-week-header;
}

.datetime-picker .picker>.date-picker>.day-of-week-header>.day-of-week {
	text-transform: uppercase;
}

.datetime-picker .picker>.date-picker>.day-picker {
	display              : grid;
	justify-items        : stretch;
	align-items          : stretch;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows   : repeat(6, 1fr);
	grid-area            : day-picker;
}

.datetime-picker .picker>.date-picker>.day-picker>.date {
	display      : grid;
	place-content: center;
	cursor       : pointer;
}

.datetime-picker .picker>.date-picker>.day-picker>.date.disabled {
	filter: opacity(0.3);
	cursor   : default;
}

.datetime-picker .picker>.date-picker>.day-picker>.date.empty {
	cursor: default;
}

.datetime-picker .picker>.date-picker>.day-picker>.date.today {
	font-weight    : bold;
	color          : var(--accent-color);
	text-decoration: underline;
}

.datetime-picker .picker>.date-picker>.day-picker>.date.selected {
	color           : white;
	background-color: var(--accent-color);
}

.datetime-picker .picker>.date-picker>.today.button {
	justify-self    : center !important;
	display         : grid;
	place-items     : center;
	cursor          : pointer;
	grid-area       : today-button !important;
	background-color: var(--accent-color);
	margin          : 0 !important;
	padding         : 0 !important;
	height          : 100%;
	width           : 80px !important;
	border          : none;
}

.datetime-picker .picker>.time-picker {
	width                : 300px;
	height               : 300px;
	top                  : 0;
	left                 : 0;
	z-index              : 2;
	grid-area            : time;
	display              : grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas  : 'hour minutes marker';
	place-items          : center;
	position             : relative;
}

.datetime-picker .picker>.time-picker>.hour-picker {
	height    : 250px;
	width     : 100%;
	overflow-y: scroll;
	grid-area : hour;
	text-align: center;

	-ms-overflow-style: none;
	scrollbar-width   : none;
}

.datetime-picker .picker>.time-picker>.minutes-picker {
	height    : 250px;
	width     : 100%;
	overflow-y: scroll;
	grid-area : minutes;
	text-align: center;

	-ms-overflow-style: none;
	scrollbar-width   : none;
}

.datetime-picker .picker>.time-picker>.time-marker-picker>.time-marker,
.datetime-picker .picker>.time-picker>.minutes-picker>.minute,
.datetime-picker .picker>.time-picker>.hour-picker>.hour {
	font-size: 2rem !important;
	padding  : 0.5rem !important;
}

.datetime-picker .picker>.time-picker>.hour-picker::-webkit-scrollbar,
.datetime-picker .picker>.time-picker>.minutes-picker::-webkit-scrollbar,
.datetime-picker .picker>.time-picker>.time-marker-picker::-webkit-scrollbar {
	display: none;
}

.datetime-picker .picker>.time-picker>.time-marker-picker {
	grid-area : marker;
	height    : 250px;
	width     : 100%;
	overflow-y: scroll;
	grid-area : marker;
	text-align: center;
	font-size : 2rem !important;
}

.datetime-picker .picker>.time-picker>.time-marker-picker>.time-marker:first-child {
	padding-top   : calc(125px - 1.45rem) !important;
	padding-bottom: 0.5rem !important;
}

.datetime-picker .picker>.time-picker>.time-marker-picker>.time-marker:last-child {
	padding-bottom: calc(125px - 1.45rem) !important;
	padding-top   : 0.5rem !important;
}

.datetime-picker .picker>.time-picker>.selected-row {
	background-color: black;
	opacity         : 0.2;
	height          : 3rem;
	width           : 100%;
	position        : absolute;
	top             : calc(150px - 2rem);
	pointer-events  : none;
	padding         : 0.5rem 0;
}